<template>
  <div id="app">
    <Header v-if="$route.name != 'login'" />
    <main class="container mx-auto px-6 mb-20">
      <router-view />
    </main>
  </div>
</template>

<script>
import Header from "@/components/Header";

export default {
  name: "app",
  components: {
    Header,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Mulish:400,700");

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
</style>
