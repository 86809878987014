import * as API from "@/services/API";

export default {
  getConcept(id) {
    return API.apiClient.get(`/concepts/${id}`);
  },
  getSlides(concept_id) {
    return API.apiClient.get(`/concepts/${concept_id}/slides`);
  },
  getFavSlides(concept_id) {
    return API.apiClient.get(`/concepts/${concept_id}/favSlides`);
  },
  getConcepts(topic_id) {
    return API.apiClient.get(`/topics/${topic_id}/concepts`);
  },
  add(payload) {
    return API.apiClient.post(`/topics/${payload.topic_id}/concepts`, payload);
  },
  update(payload) {
    return API.apiClient.put(`/topics/${payload.topic_id}/concepts/${payload.id}`, payload);
  },
};
