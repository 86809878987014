import Vue from "vue";
import store from "@/store/index";
import VueRouter from "vue-router";
import auth from "@/middleware/auth";
import superAdmin from "@/middleware/superAdmin";
import teacher from "@/middleware/teacher";
import teachingAssistant from "@/middleware/teachingAssistant";
import middlewarePipeline from "@/router/middlewarePipeline";

Vue.use(VueRouter);

const routes = [
  {
    path: "/curriculum",
    name: "curriculum",
    meta: { auth: [superAdmin, teacher, teachingAssistant] },
    component: () => import(/* webpackChunkName: "curriculum" */ "../views/Curriculum"),
  },
  {
    path: "/users",
    name: "users",
    meta: { auth: [superAdmin] },
    component: () => import(/* webpackChunkName: "users" */ "../views/Users"),
  },
  {
    path: "/schools",
    name: "schools",
    meta: { auth: [superAdmin] },
    component: () => import(/* webpackChunkName: "schools" */ "../views/Schools"),
  },
  {
    path: "/topic/:topic_id",
    name: "topic",
    meta: { auth: [superAdmin, teacher, teachingAssistant] },
    component: () => import(/* webpackChunkName: "topic" */ "../views/Topic"),
  },
  {
    path: "/concept/:concept_id",
    name: "concept",
    meta: { auth: [superAdmin, teacher, teachingAssistant] },
    component: () => import(/* webpackChunkName: "concept" */ "../views/Concept"),
  },
  {
    path: "/concept/:concept_id/slide/:slide_id",
    name: "concept",
    meta: { auth: [superAdmin, teacher, teachingAssistant] },
    component: () => import(/* webpackChunkName: "concept" */ "../views/Slide"),
  },
  {
    path: "/concept/:concept_id/slide-edit/:slide_id",
    name: "concept",
    meta: { auth: [superAdmin, teacher, teachingAssistant] },
    component: () => import(/* webpackChunkName: "concept" */ "../views/SlideEdit"),
  },
  {
    path: "/",
    name: "login",
    component: () => import(/* webpackChunkName: "login" */ "../views/Login"),
  },
  {
    path: "/reset-password",
    name: "resetPassword",
    component: () => import(/* webpackChunkName: "reset-password" */ "../views/ResetPassword"),
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: () => import(/* webpackChunkName: "forgot-password" */ "../views/ForgotPassword"),
  },
  {
    path: "/:catchAll(.*)",
    name: "notFound",
    component: () => import(/* webpackChunkName: "not-found" */ "../views/NotFound"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const middleware = to.meta.auth;
  const context = { to, from, next, store };
  const loginQuery = { path: "/", query: { redirect: to.fullPath } };

  const user = store.dispatch("auth/getAuthUser");

  if (!middleware) {
    return next();
  }

  store.dispatch("auth/getAuthUser").then(() => {
    let check = [];

    for (let index = 0; index < middleware.length; index++) {
      check.push(
        middleware[index]({
          ...context,
          next: middlewarePipeline(context, middleware, index + 1),
        })
      );
    }

    console.log("check", check);

    if (check.includes("authed")) {
      return next();
    } else {
      return next({ name: "notFound" });
    }
  });
});

export default router;
