import { getError } from "@/utils/helpers";
import SlideService from "@/services/SlideService";
//import { getInfo } from "@/api/auth";
//import { login, logout, getInfo } from '@/api/auth';
//import { isLogged, setLogged, removeToken } from '@/utils/auth';

export const namespaced = true;

export const state = {
  slides: [],
  favs: [],
  slide: {},
  meta: null,
  links: null,
  loading: false,
  error: null,
};

export const mutations = {
  SET_SLIDES(state, slides) {
    state.slides = slides;
  },
  SET_FAV_SLIDES(state, slides) {
    state.favs = slides;
  },
  SET_SLIDE(state, slide) {
    state.slide = slide;
  },
  SET_META(state, meta) {
    state.meta = meta;
  },
  SET_ID: (state, id) => {
    state.id = id;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_LINKS(state, links) {
    state.links = links;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  getSlide({ commit }, slide_id) {
    commit("SET_LOADING", true);
    return SlideService.getSlide(slide_id)
      .then((response) => {
        commit("SET_SLIDE", response.data.data);
        commit("SET_LOADING", false);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      });
  },
  getSlides({ commit }, concept_id) {
    commit("SET_LOADING", true);
    SlideService.getConcepts(concept_id)
      .then((response) => {
        commit("SET_CONCEPTS", response.data.data);
        commit("SET_LOADING", false);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      });
  },
  getFavs({ commit }) {
    commit("SET_LOADING", true);
    SlideService.getFavs()
      .then((response) => {
        commit("SET_FAV_SLIDES", response.data.data);
        commit("SET_LOADING", false);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      });
  },
  add({ commit }, payload) {
    return SlideService.add(payload).then((response) => {
      //setPaginatedMessages(commit, response);
    });
  },
};

export const getters = {
  slides: (state) => {
    return state.slides;
  },
  favs: (state) => {
    return state.favs;
  },
  slide: (state) => {
    
    return state.slide;
  },
  meta: (state) => {
    return state.meta;
  },
  links: (state) => {
    return state.links;
  },
  loading: (state) => {
    return state.loading;
  },
  error: (state) => {
    return state.error;
  },
};
