import Vue from "vue";
import Vuex from "vuex";

import * as auth from "@/store/modules/Auth";
import * as user from "@/store/modules/User";
import * as school from "@/store/modules/School";
import * as topic from "@/store/modules/Topic";
import * as area from "@/store/modules/Area";
import * as concept from "@/store/modules/Concept";
import * as slide from "@/store/modules/Slide";
import * as message from "@/store/modules/Message";
import * as permissions from "@/store/modules/Permissions";

Vue.use(Vuex);

export default new Vuex.Store({
  //strict: true,

  modules: {
    auth,
    user,
    topic,
    school,
    area,
    slide,
    message,
    concept,
    permissions,
  },
});
