import { getError } from "@/utils/helpers";
import SlideService from "@/services/SlideService";
import ConceptService from "@/services/ConceptService";
//import { getInfo } from "@/api/auth";
//import { login, logout, getInfo } from '@/api/auth';
//import { isLogged, setLogged, removeToken } from '@/utils/auth';

export const namespaced = true;

export const state = {
  slides: [],
  concept: {},
  meta: null,
  links: null,
  loading: false,
  error: null,
  loadingSlides: false,
  loadedSlides: false
};

export const mutations = {
  SET_SLIDES(state, slides) {
    state.slides = slides;
  },
  SET_CONCEPT(state, concept) {
    state.concept = concept;
  },
  SET_META(state, meta) {
    state.meta = meta;
  },
  SET_ID: (state, id) => {
    state.id = id;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_LINKS(state, links) {
    state.links = links;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_LOADING_SLIDES(state, loading) {
    state.loadingSlides = loading;
  },
  SET_LOADED_SLIDES(state, loading) {
    state.loadedSlides = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  getConcept({ commit }, concept_id) {
    commit("SET_LOADING", true);
    return ConceptService.getConcept(concept_id)
      .then((response) => {
        commit("SET_CONCEPT", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      });
  },
  getConcepts({ commit }, topic_id) {
    commit("SET_LOADING", true);
    return ConceptService.getConcepts(topic_id)
      .then((response) => {
        commit("SET_SLIDES", response.data.data);
        commit("SET_LOADING", false);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      });
  },
  getSlides({ commit }, concept_id) {
    commit("SET_LOADING_SLIDES", true);
    return ConceptService.getSlides(concept_id)
      .then((response) => {
        response.data.data.dropdownShow = false
        commit("SET_SLIDES", response.data.data);
        
        commit("SET_LOADING_SLIDES", false);
        commit("SET_LOADED_SLIDES", true);
        
      })
      .catch((error) => {
        commit("SET_LOADING_SLIDES", false);
        commit("SET_ERROR", getError(error));
      });
  },
  add({ commit }, payload) {
    return ConceptService.add(payload).then((response) => {
      //setPaginatedMessages(commit, response);
    });
  },
};

export const getters = {
  slides: (state) => {
    return state.slides;
  },
  concept: (state) => {
    return state.concept;
  },
  concepts: (state) => {
    return state.concepts;
  },
  meta: (state) => {
    return state.meta;
  },
  links: (state) => {
    return state.links;
  },
  loading: (state) => {
    return state.loading;
  },
  loadingSlides: (state) => {
    return state.loadingSlides;
  },
  loadedSlides: (state) => {
    return state.loadedSlides;
  },
  error: (state) => {
    return state.error;
  },
};
