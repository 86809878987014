import * as API from "@/services/API";

export default {
  getSchool(id) {
    return API.apiClient.get(`/schools/${id}`);
  },
  getSchools(page) {
    return API.apiClient.get(`/schools?page=${page}`);
  },
  add(payload) {
    return API.apiClient.post(`/schools`, payload);
  },
  update(payload) {
    return API.apiClient.put(`/schools/${payload.id}`, payload);
  },
  paginateSchools(link) {
    return API.apiClient.get(link);
  },
};
