<template>
  <header class="text-white bg-gray-900">
    <nav class="flex items-center justify-between flex-wrap bg-teal-500 p-6 container mx-auto">
      <div class="flex items-center flex-shrink-0 text-white mr-10">
        <span class="font-semibold text-xl tracking-tight"><a href="/curriculum">themathsplatform</a></span>
      </div>
      <div class="block lg:hidden">
        <button class="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white">
          <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div class="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
        <div class="lg:flex-grow">
          <a href="/curriculum" class="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4"> Curriculum </a>
          <router-link to="/users" v-permission="['users.view']" class="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">Accounts</router-link>
          <router-link to="/schools" v-permission="['schools.view']" class="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">Schools</router-link>
        </div>
        <div>
          <Logout />
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import Logout from "@/components/Logout";


export default {
  name: "Header",
  components: {
    Logout,
  },
  computed: {
    ...mapGetters("auth", ["authUser", "isAdmin"]),
  },
};
</script>
