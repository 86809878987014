import * as API from "@/services/API";

export default {
  getTopic(id) {
    return API.apiClient.get(`/topics/${id}`);
  },
  getTopics() {
    return API.apiClient.get(`/topics`);
  },
  getConcepts(topic_id) {
    return API.apiClient.get(`/topics/${topic_id}/concepts`);
  },
  getSkills(topic_id) {
    return API.apiClient.get(`/topics/${topic_id}/skills`);
  },
  add(payload) {
    return API.apiClient.post(`/topics`, payload);
  },
  update(payload) {
    return API.apiClient.put(`/topics/${payload.id}`, payload);
  },
};
