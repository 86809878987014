export default function teachingAssistant({ to,  next, store }) {
  const loginQuery = { path: "/", query: { redirect: to.fullPath } };

  let roles = [];
  if (store.getters["auth/authUser"]) {
    roles = store.getters["auth/authUser"].roles;
    if (roles.includes("teaching-assistant")) {
      return 'authed'
    } else {
      return { name: "notFound" }
    }
  } else {
    return 'not-authed'
  }

}
