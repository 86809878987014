import * as API from "@/services/API";

export default {
  getArea(id) {
    return API.apiClient.get(`/areas/${id}`);
  },
  getAreas(page) {
    return API.apiClient.get(`/areas`);
  },
  add(payload) {
    return API.apiClient.post(`/areas`, payload);
  },
  update(payload) {
    return API.apiClient.put(`/areas/${payload.id}`, payload);
  },
  paginateArea(link) {
    return API.apiClient.get(link);
  },
};
