import * as API from "@/services/API";

export default {
  getSlide(id) {
    return API.apiClient.get(`/slides/${id}`);
  },
  getSlides(concept_id) {
    return API.apiClient.get(`/concepts/${concept_id}/slides`);
  },
  add(payload) {
    return API.apiClient.post(`/concepts/${payload.concept_id}/slides`, payload);
  },
  update(payload) {
    return API.apiClient.put(`/concepts/${payload.concept_id}/slides/${payload.id}`, payload);
  },
  updateFav(payload) {
    return API.apiClient.put(`/favourites/${payload.id}`, payload);
  },
  getFavs() {
    return API.apiClient.get(`/favourites`);
  },
};
