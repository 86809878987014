<template>
  <div>
    <a v-if="!button && !hidden && !this.$slots.default" :class="buttonClass" :title="title" @click="show">
      {{ title }}
    </a>
    <span @click="show" :class="buttonClass">
      <slot></slot>
    </span>
    <a v-if="button && !hidden" :class="buttonClass" class="button" @click="show" :title="title">
      {{ title }}
    </a>

    <div class="fixed w-full h-full top-0 left-0 flex items-center justify-center z-20" v-if="open">
      <div class="absolute w-full h-full bg-gray-900 opacity-50" @click="close"></div>

      <div class="absolute z-20" :class="maxWidth">
        <div class="container bg-white max-h-screen md:rounded overflow-scroll">
          <div class="px-3 py-1 leading-none flex justify-between items-center font-medium text-sm bg-gray-100 border-b select-none">
            <h3 v-if="title" class="font-bold text-lg text-gray-700">{{ title }}</h3>
            <div @click="close" class="text-2xl hover:text-gray-600 cursor-pointer text-gray-700">&#215;</div>
          </div>

          <div class="relative">
            <div :class="loading ? 'absolute' : 'hidden'" class="top-0 left-0 right-0 bottom-0 w-full h-full z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
              <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
            </div>
            <div class="px-4 py-4">
              <component :is="getComponent" :attributes="attributes"></component>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false,
      loading: false,
    };
  },
  props: {
    id: null,
    title: null,
    component: null,
    buttonClass: null,
    width: {
      type: String,
      default: "full",
      validator: (value) => ["xs", "sm", "md", "lg", "xl", "full"].indexOf(value) !== -1,
    },
    button: {
      type: Boolean,
      default: false,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    attributes: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  created() {
    this.$eventBus.$on("modal-loading", (val) => {
      this.loading = val;
    });
    //console.log('basket modal', this.attributes);
  },
  computed: {
    maxWidth() {
      switch (this.width) {
        case "xs":
          return "max-w-lg";
        case "sm":
          return "max-w-xl";
        case "md":
          return "lg:w-100 max-w-2xl";
        case "lg":
          return "lg:w-200 max-w-3xl";
         case "xl":
          return "lg:w-300 max-w-4xl md:w-3/4";
        case "full":
          return "max-w-full";
        default:
          return "max-w-lg";
      }
    },
    getComponent () {
      if (this.component) {
        return () => import(`@/components/modals/${this.component}`);
      }
      return null;
  }
  },
  methods: {
    show() {
      this.open = true;
    },
    close() {
      this.open = false;
    },
  },
};
</script>
