import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/css/app.css";
import Modal from "@/components/Modal.vue";
import VueFullscreen from 'vue-fullscreen'

//import VueFullscreen from 'vue-fullscreen'

Vue.prototype.$eventBus = new Vue();

Vue.use(VueFullscreen)

Vue.directive("permission", {
  // When the bound element is inserted into the DOM...
  async inserted(el, binding, vnode) {
    const { value } = binding;
    const user = await store.dispatch("auth/getAuthUser");

    //console.log('user', user);

    if (user) {
      const permissions = user.permissions;

      if (value && value instanceof Array && value.length > 0) {
        const requiredPermissions = value;
        const hasPermission = permissions.some((permission) => {
          return requiredPermissions.includes(permission);
        });

        if (!hasPermission) {
          el.parentNode && el.parentNode.removeChild(el);
        }
      } else {
        vnode.elm.style.display = "none";
      }
    } else {
      vnode.elm.style.display = "none";
      return false
    }
  },
});

Vue.directive("role", {
  // When the bound element is inserted into the DOM...
  async inserted(el, binding) {
    const { value } = binding;
    const user = await store.dispatch("auth/getAuthUser");
    
    if (user) {
      const roles = user.roles;

      if (value && value instanceof Array && value.length > 0) {
        const requiredRoles = value;
        const hasRole = roles.some((role) => {
          return requiredRoles.includes(role);
        });

        if (!hasRole) {
          el.parentNode && el.parentNode.removeChild(el);
        }
      } else {
        vnode.elm.style.display = "none";
        throw new Error(
          `Roles are required! Example: v-role="['admin','editor']"`
        );
      }
    } else {
      vnode.elm.style.display = "none";
      return false
    }
  },
});

Vue.filter("TitleCase", value => {
  return value.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
});

Vue.component("modal", Modal);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
