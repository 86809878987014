import { getError } from "@/utils/helpers";
import TopicService from "@/services/TopicService";
//import { getInfo } from "@/api/auth";
//import { login, logout, getInfo } from '@/api/auth';
//import { isLogged, setLogged, removeToken } from '@/utils/auth';

export const namespaced = true;

export const state = {
  areas: [],
  concepts: [],
  skills: [],
  topic: {},
  meta: null,
  links: null,
  loading: false,
  error: null,
};

export const mutations = {
  SET_TOPIC(state, topic) {
    state.topic = topic;
  },
  SET_TOPICS(state, topics) {
    state.topics = topics;
  },
  SET_CONCEPTS(state, concepts) {
    state.concepts = concepts;
  },
  SET_SKILLS(state, skills) {
    state.skills = skills;
  },
  SET_META(state, meta) {
    state.meta = meta;
  },
  SET_ID: (state, id) => {
    state.id = id;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_LINKS(state, links) {
    state.links = links;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  getTopic({ commit }, topic_id) {
    commit("SET_LOADING", true);
    TopicService.getTopic(topic_id)
      .then((response) => {
        commit("SET_TOPIC", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      });
  },
  getTopics({ commit }, page) {
    commit("SET_LOADING", true);
    TopicService.getTopics(page)
      .then((response) => {
        commit("SET_TOPICS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      });
  },
  getConcepts({ commit }, topic_id) {
    commit("SET_LOADING", true);
    return TopicService.getConcepts(topic_id)
      .then((response) => {
        commit("SET_CONCEPTS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      });
  },
  getSkills({ commit }, topic_id) {
    commit("SET_LOADING", true);
    return TopicService.getSkills(topic_id)
      .then((response) => {
        commit("SET_SKILLS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      });
  },
  add({ commit }, payload) {
    return TopicService.add(payload).then((response) => {
      //setPaginatedMessages(commit, response);
    });
  },
};

export const getters = {
  topic: (state) => {
    return state.topic;
  },
  topics: (state) => {
    return state.topics;
  },
  concepts: (state) => {
    return state.concepts;
  },
  skills: (state) => {
    return state.skills;
  },
  meta: (state) => {
    return state.meta;
  },
  links: (state) => {
    return state.links;
  },
  loading: (state) => {
    return state.loading;
  },
  error: (state) => {
    return state.error;
  },
};
